import '../../vendors/dayjs_verbose/dayjs.verbose-diff';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

let defaults = {
  seconds: 1
};

function Deadline(element, options) {
  this.element = element;
  this.options = $.extend({}, defaults, options);

  this.init();
}

Deadline.prototype.init = function () {
  let $el = $(this.element);
  let opts = this.options;

  $el.each(function () {
    let $this = $(this);

    setInterval(function () {
      countDown($this);
    }, 1000 * opts.seconds);
  });
};

function countDown($el) {
  let deadline = dayjs(new Date($el.attr('data-deadline')));
  let seconds = Math.floor(deadline.diff(dayjs()) / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);
  hours %= 24;
  minutes %= 60;
  seconds %= 60;

  if (!$el.find('.days span.value, .btn-arrow span.value').length) {
    // console.log(diff.days * 24);
    if (days !== undefined && hours !== undefined) $el.find('.hours span').html(+hours + days * 24);
  } else {
    $el.find('.days span.value, .btn-arrow span.value').html(days);
    $el.find('.hours span.value').html(hours);
  }
  $el.find('.mins span.value').html(minutes.toString().padStart(2, '0'));
  $el.find('.secs span.value').html(seconds.toString().padStart(2, '0'));
}


$.fn.deadline = function (options) {
  return this.each(function () {
    if (!$.data(this, 'plugin_deadline')) {
      $.data(this, 'plugin_deadline', new Deadline(this, options));
    }
  });
};
