import '../../shared/deadline/deadline';

$(function () {
  let $pre_reservations_index_list = $('.pre-reservations-index-list');

  if (!$pre_reservations_index_list.length) return false;

  $pre_reservations_index_list.find('.item').each(function (index, element) {
    let $this = $(element);

    $this.find('.item-counter').deadline();
  });
});
