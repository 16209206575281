
import { getLazyData } from './get_lazy_data'

/**
 * Makes a job request and watchs for its response
 * @param {string} url - request url
 * @param {number} timeout - timeout in ms after each request
 * @param {function} onResponse - function to call when job responds with any response (partial or complete)
 * @param {function} onPartialResponse - function to call when job responds with a not complete response
 * @param {function} onComplete - function to call when job responds with a complete response
 * @returns {string} response
 *
 * @example
 * watchJob({
    url: 'joburl/job?param="2"',
    onResponse: (response) => {
      console.log(`Last response is ${response}`);
    },
    onPartialResponse: (response) => {
      console.log(`Partial response is ${response}`);
    },
    onComplete: (response) => {
      console.log(`Complete response is ${response}`);
    }
  });
 */
const watchJob = ({ url, timeout, onResponse, onPartialResponse, onComplete }) => {
  if (!url || typeof url !== 'string' || !url.length) return

  const getData = () => {
    getLazyData(url, response => {
      if (!response || !response.length) return

      const { at, total } = JSON.parse(response)

      if (at !== total) {
        onPartialResponse && onPartialResponse(response)

        if (timeout) {
          setTimeout(() => {
            getData()
          }, timeout)
        } else {
          getData()
        }
      } else {
        onComplete && onComplete(response)
      }

      onResponse && onResponse(response)
    })
  }

  getData()
}

export default watchJob
