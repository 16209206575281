import { sendUtagEvent } from '../../core/analytics/utag_events'
import on from '../../utils/events_delegation'

export function initializeUtagEvents() {
  // repeat reservation
  on({
    eventName: 'click',
    selector: '.repeat-reservation',
    handler() {
      sendUtagEvent({
        data: {
          event_name: 'manage_booking',
          event_cat: 'manage my booking',
          event_act: 'repeat booking',
          event_lbl: '',
          event_purpose: 'booking',
          event_structure: 'body',
        },
      })
    },
  })
}
