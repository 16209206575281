import '../../shared/pre_reservations_index_list/pre_reservations_index_list'

/* eslint-disable */
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import minMax from 'dayjs/plugin/minMax'
import _uniq from 'lodash/uniq'

import { closeLoader, openLoader } from '../../elements/wait_loading/wait_loading';
import watchJob from '../../utils/watch_job'
import { initBottomLinks } from '../bottom_links/bottom_links'
import loaderSpinner from '../loader_spinner/loader_spinner'

import { initializeUtagEvents } from './dashboard_booking_row.analytics'

dayjs.extend(customParseFormat)
dayjs.extend(minMax)
dayjs.extend(isBetween)

initializeUtagEvents()

export function initDashboardBookingRow() {
  const $dashboard_booking_row = $('.dashboard-booking-row')
  const logged_from_call_center = $('.dashboard-booking-row').data('logged-from-call-center')

  if (!$dashboard_booking_row.length) return

  if (logged_from_call_center) {
    disableBookingOptionsIfLoginCallCenter()
    return
  }

  const is_mobile = IB.currentDevice === 'mobile'
  const $repeat_reservation = $('#repeat-reservation')

  if (is_mobile) {
    $dashboard_booking_row.find('.block').each(function (index, element) {
      const $element = $(element)
      const $code = $element.find('.code')
      const $label = $code.prev('.label:not(.points)')
      const $left = $element.find('.left')
      const $cart_info = $element.find('.card-info')

      $left.find('.booked').length
        ? $code.closest('div').insertAfter($left.find('.booked'))
        : $left.prepend($code.closest('div'))
      $label.text(`${$label.text()}:`)

      if ($cart_info.length) {
        const $cart_info_ul = $('<ul class="card-info"></ul>')
        $cart_info.find('td').each(function (index, element) {
          $cart_info_ul.append($(`<li>${element.innerHTML}</li>`))
        })

        $cart_info.replaceWith($cart_info_ul)
      }
    })

    const $order = $dashboard_booking_row.find('.results-order-by')

    if ($order.length) {
      const label = $order.find('> .js-results-order-text').first().text().replace(':', '')
      const $select_label = $(`<label class="t-fb-label">${label}</label>`)
      const $select = $(
        '<div class="d-i-select-cnt formz"><div class="fz-row"><div class="fz-col"><div class="fz-field"><select class="hotels-order-select t-p"></select></div></div></div></div>'
      )

      $order.closest('.dashboard-booking-row').prepend($select)
      $('.d-i-select-cnt').find('.fz-field').prepend($select_label)

      $('.js-results-order-by')
        .first()
        .find('.drop-down-item')
        .each(function (index, el) {
          const $link = $(el).find('a')
          $('.hotels-order-select').append(`<option value="${$link.data('js-selector')}">${$link.text()}</option>`)
        })

      // Triggeamos el click al cambio del select
      $(document).on('change', '.hotels-order-select', function (event) {
        event.preventDefault()
        const order = $(this).val()
        $(`[data-js-selector="${order}"]`).trigger('click')
      })
    }
  }

  if ($dashboard_booking_row.hasClass('quote')) $dashboard_booking_row.find('.item-counter').deadline()





  $('.repeat-reservation').fancybox({
    touch: false,
    beforeShow(instance, current) {
      initDatepickerRepeatReservation()
      const $current = $(current.src)

      $current
        .find('.title')
        .find('.name')
        .text(current.opts.$orig.closest('.js-repeat-block-cnt').find('.block').data('hotel-name'))
      $current.data('code', current.opts.$orig.closest('div').find('form').data('code'))
    },
    afterShow() {
      $('.real-today').trigger('focus')
    },
    afterClose() {
      $('.b-date-selection-input').data('dateRangePicker').clear()
      $('.b-date-selection-input').data('dateRangePicker').destroy()
      $repeat_reservation.removeClass('active-alert')
      $repeat_reservation.find('.dates-info').addClass('hidden')
      $repeat_reservation.find('.action').find('.btn-primary').addClass('disabled')
    },
  })

  $(document).on('click', '.close-repeat-booking', function (e) {
    e.preventDefault()

    parent.jQuery.fancybox.getInstance().close()
  })

  $repeat_reservation.on('hover', '.btn-primary.disabled', function (e) {
    e.preventDefault()
  })

  $(document).on('click', '.repeat-booking-button', function (e) {
    e.preventDefault()
    const bookingCode = $('#repeat-reservation').data('code')
    const $form = $(`#repeat-booking-form-${bookingCode}`)

    $form.trigger('submit')
  })
}

function initDatepickerRepeatReservation() {
  const $b_date_selection_datepicker = $('.b-date-selection-datepicker')

  if (!$b_date_selection_datepicker.length) return false

  const $input = $('.b-date-selection-input')
  const language = document.documentElement.getAttribute('data-js-lang')
  let date_format = $('html').attr('data-date-format')
  const start_of_week = $('html').attr('data-start-of-week')
  const is_mobile = IB.currentDevice === 'mobile'

  if (is_mobile) {
    date_format = date_format === 'DD/MM/YYYY' ? 'DD/MM/YY' : 'MM/DD/YY'
  }

  const start_date = new dayjs().format(date_format)

  if ($('.active-booking').length) $('#repeat-reservation').find('.bootom-info').addClass('active')

  $input
    .dateRangePicker({
      format: date_format,
      startOfWeek: start_of_week,
      language,
      startDate: start_date,
      inline: true,
      minDays: 2,
      container: '.b-date-selection-datepicker',
      alwaysOpen: true,
      singleMonth: is_mobile,
      selectForward: true,
      stickyMonths: true,
      showTopbar: false,
      hoveringTooltip: false,
      showDateFilter(time, date) {
        let has_booking = false
        let first_reserved_day = false
        let last_reserved_day = false
        let bookings_days = []
        const booking_number = []
        let booking_code

        $('.active-booking').each(function (index, element) {
          const $el = $(element)
          bookings_days = bookings_days.concat($el.data('dates'))
          booking_number.push($el.data('booking-number'))
        })

        for (let index = 0; index < bookings_days.length; index++) {
          const currentDate = dayjs(time).format('YYYY-MM-DD')
          const booking = bookings_days[index]
          booking_code = booking_number[index]
          const stay_from_date = dayjs.max(dayjs(booking.stay_from), dayjs()).format('YYYY-MM-DD')
          const stay_to_date = dayjs(booking.stay_to).format('YYYY-MM-DD')
          first_reserved_day =
            !dayjs(booking.stay_from).isAfter(dayjs(time), 'days') &&
            !dayjs(booking.stay_from).isBefore(dayjs(time), 'days')
              ? 'first-reserved-day'
              : ''
          last_reserved_day =
            !dayjs(booking.stay_to).isAfter(dayjs(time), 'days') &&
            !dayjs(booking.stay_to).isBefore(dayjs(time), 'days')
              ? 'last-reserved-day'
              : ''
          has_booking = dayjs(currentDate).isBetween(stay_from_date, stay_to_date, 'day', '[]')
          if (has_booking) break
        }
        const className = has_booking ? 'has-reserve' : ''
        return `<div class="number ${className} ${first_reserved_day} ${last_reserved_day}" data-code="${booking_code}">${date}</div>`
      },
    })
    .on('datepicker-change', function (event, obj) {
      const $action = $('#repeat-reservation').find('.action').find('.btn-primary')
      const code_array = []

      if ($action.hasClass('disabled')) $action.removeClass('disabled')

      setTimeout(function () {
        obj.box.find('.checked').each(function (index, element) {
          if ($(element).find('.number').hasClass('has-reserve')) {
            code_array.push($(element).find('.number').data('code'))
            if (is_mobile) {
              $('#repeat-reservation')
                .addClass('active-alert')
                .find('.alert-reserved-days')
                .find('span')
                .append(_uniq(code_array).join(', '))
            } else {
              $('#repeat-reservation')
                .addClass('active-alert')
                .find('.alert-reserved-days')
                .append(_uniq(code_array).join(', '))
            }

            return false
          }
          $('#repeat-reservation').removeClass('active-alert')
        })

        const $dates_info = obj.box.closest('.calendar-cnt').find('.dates-info')
        const dates_checkin = is_mobile
          ? dayjs(obj.date1).format(date_format)
          : `${dayjs(obj.date1).format('ddd')}, ${dayjs(obj.date1).format(date_format)}`
        const dates_checkout = is_mobile
          ? dayjs(obj.date2).format(date_format)
          : `${dayjs(obj.date2).format('ddd')}, ${dayjs(obj.date2).format(date_format)}`
        const $nights = $dates_info.find('.dates-nights')
        const diff = dayjs(obj.date2).diff(dayjs(obj.date1), 'days')
        const text = diff > 1 ? $nights.data('other') : $nights.data('one')
        const bookingCode = $('#repeat-reservation').data('code')

        $(`#check_in_date_${bookingCode}`).val(dayjs(obj.date1).format('YYYY-MM-DD'))
        $(`#check_out_date_${bookingCode}`).val(dayjs(obj.date2).format('YYYY-MM-DD'))

        $dates_info.find('.dates-checkin').text(dates_checkin)
        $dates_info.find('.dates-checkout').text(dates_checkout)
        $nights.text(`${diff} ${text}`)

        if ($dates_info.hasClass('hidden')) $dates_info.removeClass('hidden')
      }, 0)
    })
}

function disableBookingOptionsIfLoginCallCenter() {
  $('.bottom-links').remove()
  $('.bottom-links-mobile').remove()
  $('.info-booking-repeat').remove()
  $('.restricted-call-center').removeClass('hidden')
  $('.bottom-deadline').remove()
}

/**
 * Builds date with expected string format.
 * @param {String} date Date in string format
 * @returns String with expected format
 */
function buildDate(date) {
  const dateObj = new Date(JSON.parse(date))
  const day = String(dateObj.getDate()).padStart(2, '0')
  const month = dateObj.getMonth() + 1
  const year = dateObj.getFullYear()

  return `${String(year)}/${String(month)}/${day}`
}

const getBookings = () => {
  const $jobIdElement = document.querySelector('.js-bookings-job-id')

  if (!$jobIdElement) return

  const bookingsJobId = $jobIdElement?.value

  if (!bookingsJobId) return

  const $bookingsContainer = document.querySelector('.js-bookings-container')

  if (!$bookingsContainer) return

  const loaderText = $jobIdElement.dataset.waitText
  const loaderSubText = $jobIdElement.dataset.waitSubtext
  const $loaderContainer = $bookingsContainer.parentElement
  const REPLACEMENT_PROPS = {
    active: 'html_replacements_active',
    inactive: 'html_replacements_not_active',
    canceled: 'html_replacements_canceled',
  }
  const htmlProperties = [REPLACEMENT_PROPS.active, REPLACEMENT_PROPS.inactive, REPLACEMENT_PROPS.canceled]

  // show loaderSpinner
  loaderSpinner.show({
    container: $loaderContainer,
    extraClass: 'mt-7 mb-7',
    title: loaderText,
    subtitle: loaderSubText,
    spinnerColor: 'primary',
  })

  let prefix = document.querySelector('html').getAttribute('prefix')
  prefix = prefix !== '/' ? prefix : ''
  const jobRequestUrl = `${prefix}/bookings_jobs/${bookingsJobId}/`

  const handleBookingsResponse = response => {
    if (!response || !response.length) return

    const responseParsed = JSON.parse(response)

    // Concat all html_replacements
    const bookingsHtml = htmlProperties.reduce((previousValue, currentProperty) => {
      const currentHtml = responseParsed[currentProperty]

      if (!currentHtml) return `${previousValue}`

      const currentHtmlParsed = JSON.parse(responseParsed[currentProperty])
      // currentHtmlParsed is an object with all bookings which structure is { booking_id: booking_html }
      // so by obtaining all values and joining them we get all html in a unique string
      const bookingsHtmlList = Object.values(currentHtmlParsed)
      const htmlReplacementSerialized = bookingsHtmlList.join('')

      return previousValue + htmlReplacementSerialized
    }, '')

    if (bookingsHtml.length) {
      // Show bookings container if hidden
      if ($bookingsContainer.classList.contains('is-hidden')) {
        $bookingsContainer.classList.remove('is-hidden')
      }
    }

    // Append html
    $bookingsContainer.innerHTML = bookingsHtml
    IB.currencyForm.forceUpdate($bookingsContainer)
    initBottomLinks()
    initDashboardBookingRow()
  }

  const showFilterSelector = () => {
    const $filterSelector = document.querySelector('.js-bookings-filter')

    if (!$filterSelector) return

    const bookingStatePresence = {
      active: !!$bookingsContainer.querySelector('[data-category="active"]'),
      inactive: !!$bookingsContainer.querySelector('[data-category="not_active"]'),
      canceled: !!$bookingsContainer.querySelector('[data-category="canceled"]'),
    }

    if ($filterSelector.classList.contains('is-hidden')) {
      $filterSelector.classList.remove('is-hidden')
    }

    // Show proper filter options
    $filterSelector
      .querySelector('.js-bookings-filter-active')
      .classList.toggle('is-hidden', !bookingStatePresence['active'])
    $filterSelector
      .querySelector('.js-bookings-filter-inactive')
      .classList.toggle('is-hidden', !bookingStatePresence['inactive'])
    $filterSelector
      .querySelector('.js-bookings-filter-canceled')
      .classList.toggle('is-hidden', !bookingStatePresence['canceled'])
  }

  const showBookingsEmptyWrapper = () => {
    // Hide bookings container and show empty bookings container
    const $bookingsMainWrapper = document.querySelector('.js-bookings-main-wrapper')
    const $emptyBookingsMainWrapper = document.querySelector('.js-bookings-empty-wrapper')
    $bookingsMainWrapper && $bookingsMainWrapper.classList.add('is-hidden')
    $emptyBookingsMainWrapper && $emptyBookingsMainWrapper.classList.remove('is-hidden')
  }

  watchJob({
    url: jobRequestUrl,
    timeout: 1000,
    onPartialResponse: handleBookingsResponse,
    onComplete: response => {
      loaderSpinner.hide({
        container: $loaderContainer,
      })

      // Check if there is any booking
      if (!response || !response.length || !Object.values(JSON.parse(response)).length) {
        showBookingsEmptyWrapper()
      } else {
        const responseParsed = JSON.parse(response)
        const hasHtmlContent = htmlProperties.some(property => {
          if (!responseParsed[property].length) return false

          const htmlObject = JSON.parse(responseParsed[property])
          return !!Object.values(htmlObject).length
        })

        if (hasHtmlContent) {
          // Show bookings
          handleBookingsResponse(response)
          showFilterSelector()
        } else {
          showBookingsEmptyWrapper()
        }
      }
    },
  })
}

getBookings()
