import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export default (dayjsClass) => {
  
  dayjsClass.prototype.verboseDiff = function (date2) {
    return dayjsVerboseDiff(this, date2);
  };

  dayjsVerboseDiff = function (date1, date2) {
    let d1 = dayjs(date1);
    let d2 = dayjs(date2);

    if (d1.isSame(d2)) {
      return 0;
    } else if (d1.isBefore(d2)) {
      return false;
    }

    let yDiff = d1.year() - d2.year();
    let mDiff = d1.month() - d2.month();
    let dDiff = d1.date() - d2.date();
    let hDiff = d1.hour() - d2.hour();
    let minDiff = d1.minute() - d2.minute();
    let secDiff = d1.second() - d2.second();

    if (secDiff < 0) {
      secDiff = 60 + secDiff;
      minDiff--;
    }

    if (minDiff < 0) {
      minDiff = 60 + minDiff;
      hDiff--;
    }

    if (hDiff < 0) {
      hDiff = 24 + hDiff;
      dDiff--;
    }

    if (mDiff > 0 || yDiff > 0) {
      dDiff = d1.diff(d2, 'days');
    }

    return {
      'days':    dDiff.toString().length < 2 ? '0' + dDiff : dDiff,
      'hours':   hDiff.toString().length < 2 ? '0' + hDiff : hDiff,
      'minutes': minDiff.toString().length < 2 ? '0' + minDiff : minDiff,
      'seconds': secDiff.toString().length < 2 ? '0' + secDiff : secDiff
    };
  };
};
