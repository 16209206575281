export function initBottomLinks() {
  let $bottom_links = $('.bottom-links');
  let $info_booking_repeat = $('.info-booking-repeat');

  if (IB.currentDevice === 'desktop' && $('.download-pwa').length) $('.download-pwa').remove();

  if ($info_booking_repeat.length && IB.currentDevice === 'mobile') {
    // $info_booking_repeat.after($('<a href="#" class="show-booking-repeat">' + $info_booking_repeat.data('mobile-text') + '</a>'));
    $(document).on('click', '.show-booking-repeat', function (e) {
      e.preventDefault();
      $(this).closest('.block-cnt-child').find('.bottom-links-mobile')
        .toggleClass('active');
    });
  }

  if (!$bottom_links.length) return;

  $bottom_links.each(function (index, element) {
    let $element = $(element);
    let $links_container = $element.find('.bottom-links-contaniner');
    let width = $links_container.width();
    let $more_options_content = $element.find('.more-options-content');
    let $more_options = $links_container.find('.more-options');
    let is_mobile = IB.currentDevice === 'mobile' || (IB.currentDevice === 'small-mobile');

    $links_container.addClass('flex');

    if (!is_mobile) {
      checkBottomLinksWidth(width, $links_container, $more_options_content, $more_options, index);

      $(window).resize(function () {
        checkBottomLinksWidth($links_container.width(), $links_container, $more_options_content, $more_options);
      });
    } else {
      let text = $element.data('mobile-text');
      let $elements = $links_container.find('> li:not(.more-options)');

      $element.prepend($('<a href="#" class="show-bottom-links s-link">' + text + '</a>'));
      if ($elements.length % 2) $elements.last().addClass('full');
      let active_class = 'bottom-links-mobile ';
      if ($('.download-pwa').length) active_class += 'booking-with-download-pwa';
      $element.prev('.block').prepend($('<ul class="' + active_class + '"></ul>').prepend($elements));


      $(window).scroll(function () {
        let $active = $('.block-cnt >ul >li.active');
        if ($active.is(':visible')) {
          if (!checkOnScreen($active.find('.bottom-links-mobile'))) $active.removeClass('active');
        }
      });
    }
  });
}

$(document).on('click', '.show-bottom-links', function (e) {
  e.preventDefault();
  $(this).closest('.dashboard-booking-row').find('.bottom-links-mobile')
    .toggleClass('active');
});

$(document).on('click', '.more-options-link', function () {
  ifElementOnScreen($(this.hash));
});

function ifElementOnScreen($el) {
  let scroll_bottom = $(window).height() + $(window).scrollTop();
  let element_bottom = $el.offset().top + $el.outerHeight();
  if (scroll_bottom < element_bottom) {
    $('html,body').animate({
      scrollTop: $(window).scrollTop() + element_bottom - scroll_bottom + 20
    }, 500);
  }
}

function getLinksWidth($container, count) {
  let selector = count > 0 ? '> li' : '> li:not(.more-options)';
  let total = 0;

  $container.find(selector).each(function (index, element) {
    total += $(element).outerWidth(true);
  });

  return total;
}

function checkBottomLinksWidth(w, $cnt, $m_cnt, $m_o, index) {
  let count = $m_cnt.find('> li').length;
  let t_w = getLinksWidth($cnt, count);

  if (w < t_w) {
    $m_cnt.prepend($m_o.prev());
    if ($m_o.hasClass('hidden')) $m_o.removeClass('hidden');
    checkBottomLinksWidth(w, $cnt, $m_cnt, $m_o, index);
  }
}

function checkOnScreen($el) {
  let page_top = $(window).scrollTop();
  let page_bottom = page_top + $(window).height();
  let element_top = $el.offset() !== undefined ? $el.offset().top : 0;
  let element_bottom = element_top + $el.height();

  return ((element_top <= page_bottom) && (element_bottom >= page_top));
}
